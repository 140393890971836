import * as React from 'react'
import { EditorTools } from '@components/panel/panel-elements/editor-tools'
import { EVENTS_ID, DirectMeasureToolsWithParameters, Tool, SELECT_EVENT_IDS, EVENT_SELECT_TOOL_TO_TOOLS } from '@app/constants'
import { PanelElement } from '@components/panel/panel-element'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProjectDatasetContext } from '@app/api/openapi'
import { useBrushSize, useHiddenAnnotationClassIndexes, useSelectedAnnotationClassColorIndex, useSelectedTool } from '@app/hooks/editor'
import { DrawingParameters } from '@components/panel/panel-elements/drawing-parameters'
import { TrainingSection } from '@app/pages/editor-page/training-section'
import { DownloadPluginButton } from '@app/pages/editor-page/download-button'
import Title from 'antd/lib/typography/Title'
import styles from '../styles/editor-page.module.scss'
import { Carousel, Flex, Image, Modal, Space } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import InfoOutlinedIcon from '@material-design-icons/svg/outlined/info.svg'
import { ToolParameters } from '@components/panel/panel-elements/tool-parameters'
import DirectMeasureTools from '@components/panel/panel-elements/direct-measure-tools'
import { useEditorStore } from '@app/stores/editor'
import { MetadataAnnotationTools } from '@components/panel/panel-elements/metadata-annotation-tools'
import SelectedAnnotationArrowParameters from '@components/panel/panel-elements/selected-annotation-arrow-parameters'
import { FEATURE_TYPE, FeatureType } from '@clemex/mosaic-canvas'
import { PredictingStatus } from '@app/pages/editor-page/training/predicting-status'
import { ImageParameters } from '@components/panel/panel-elements/image-parameters'
import { useImageFilterStore } from '@app/stores/image-filter'

interface EditorRightPanelProps {
  projectId: string
  context: ProjectDatasetContext
}

export const EditorRightPanel: React.FC<EditorRightPanelProps> = ({ projectId, context }) => {
  const intl = useIntl()
  const [selectedTool, setSelectedTool] = useSelectedTool()
  const [brushSize, setBrushSize] = useBrushSize()
  const [selectedAnnotationClassIndex] = useSelectedAnnotationClassColorIndex()
  const [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes] = useHiddenAnnotationClassIndexes()
  const resetEditorStoreFromContext = useEditorStore((state) => state.resetFromContext)
  const setDirectMeasureAndMetadataAnnotationVisibility = useEditorStore((state) => state.setDirectMeasureAndMetadataAnnotationVisibility)
  const selectedItems = useEditorStore((state) => state.selectedItems)
  const [isImageFilterOpen] = useImageFilterStore((state) => [state.isOpen])

  const [downloadExplanationModal, downloadExplanationContextHolder] = Modal.useModal()

  const selectBrush = React.useCallback((): void => {
    if (hiddenAnnotationClassIndexes.includes(selectedAnnotationClassIndex)) {
      setHiddenAnnotationClassIndexes(hiddenAnnotationClassIndexes.filter((index) => index !== selectedAnnotationClassIndex))
    }
  }, [hiddenAnnotationClassIndexes, selectedAnnotationClassIndex, setHiddenAnnotationClassIndexes])

  const showDirectMeasureAndMetadataAnnotationVisibility = React.useCallback((): void => {
    setDirectMeasureAndMetadataAnnotationVisibility(true)
  }, [setDirectMeasureAndMetadataAnnotationVisibility])

  const hideDirectMeasureAndMetadataAnnotationVisibility = React.useCallback((): void => {
    setDirectMeasureAndMetadataAnnotationVisibility(false)
  }, [setDirectMeasureAndMetadataAnnotationVisibility])

  React.useEffect(() => {
    const eventHandlers = SELECT_EVENT_IDS.map((eventId) => {
      const handler = (): void => {
        const tool = EVENT_SELECT_TOOL_TO_TOOLS[eventId]
        if (tool !== undefined) {
          setSelectedTool(tool)
        } else {
          console.error(`No tool found for event id: ${eventId}`)
        }
      }
      return [eventId, handler] as const
    })
    eventHandlers.forEach(([eventId, handler]) => {
      window.addEventListener(eventId, handler, {})
    })
    window.addEventListener(EVENTS_ID.EDITOR_BRUSH_TOOL, selectBrush, {})
    window.addEventListener(EVENTS_ID.EDITOR_TOOL_SHOW_DIRECT_MEASURE_AND_METADATA_ANNOTATION, showDirectMeasureAndMetadataAnnotationVisibility, {})
    window.addEventListener(EVENTS_ID.EDITOR_TOOL_HIDE_DIRECT_MEASURE_AND_METADATA_ANNOTATION, hideDirectMeasureAndMetadataAnnotationVisibility, {})
    return () => {
      eventHandlers.forEach(([eventId, handler]) => {
        window.removeEventListener(eventId, handler, {})
      })
      window.removeEventListener(EVENTS_ID.EDITOR_BRUSH_TOOL, selectBrush, {})
      window.removeEventListener(EVENTS_ID.EDITOR_TOOL_SHOW_DIRECT_MEASURE_AND_METADATA_ANNOTATION, showDirectMeasureAndMetadataAnnotationVisibility, {})
      window.removeEventListener(EVENTS_ID.EDITOR_TOOL_HIDE_DIRECT_MEASURE_AND_METADATA_ANNOTATION, hideDirectMeasureAndMetadataAnnotationVisibility, {})
    }
  }, [hideDirectMeasureAndMetadataAnnotationVisibility, selectBrush, setSelectedTool, showDirectMeasureAndMetadataAnnotationVisibility])

  React.useEffect(() => {
    resetEditorStoreFromContext(context)
  }, [context, resetEditorStoreFromContext])

  const showDrawingParameters = (selectedTool === Tool.BRUSH || selectedTool === Tool.ERASER) && context === ProjectDatasetContext.Training
  const showToolParameters = DirectMeasureToolsWithParameters.includes(selectedTool)
  const showSelectedAnnotationArrowParametersPanel = selectedItems.filter((item) => item.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_ARROW).length === 1

  const openDownloadExplanationModal = (): void => {
    void downloadExplanationModal.info({
      title: intl.formatMessage({ id: 'right-panel.download.modal.title', defaultMessage: 'What is a plugin ?' }),
      content: <>
        <Space direction="vertical" size="middle" className={styles.downloadExplanation}>
          <Paragraph >
            {
              intl.formatMessage({
                id: 'right-panel.download.modal.content-1',
                defaultMessage: 'A plugin is a software that can be installed in Clemex Vision to be added in a routine and help you detect the objects you have trained on.',
              })
            }
          </Paragraph>
          <Paragraph >
            {
              intl.formatMessage({
                id: 'right-panel.download.modal.content-2',
                defaultMessage: 'It enables you to use your trained model on a new set of images and measure the properties of the detected objects.',
              })
            }
          </Paragraph>
          <Paragraph >
            {
              intl.formatMessage({
                id: 'right-panel.download.modal.content-3',
                defaultMessage: 'Clemex Vision is a fully integrated system for labs seeking traceable, repeatable, and accurate results.',
              })
            }
          </Paragraph>
          <Carousel autoplay autoplaySpeed={3000} className={styles.downloadCarousel}>
            <Image src="/images/editor/download-modal-vision.webp" preview={false}/>
            <Image src="/images/editor/download-modal-vision-traceable-data.webp" preview={false}/>
            <Image src="/images/editor/download-modal-vision-reporting.webp" preview={false}/>
          </Carousel>
        </Space>
      </>,
      centered: true,
      width: window.innerWidth / 2,
      icon: null,
      maskClosable: true,
      closable: true,
      transitionName: '',
    })
  }

  return <div className={styles.rightPanelContainer}>
    <Flex vertical className={styles.rightPanelTop}>
      <PanelElement underline={true}>
        <EditorTools
          projectId={projectId}
          context={context}
        />
      </PanelElement>
      <PanelElement underline>
        <DirectMeasureTools />
      </PanelElement>
      <PanelElement underline={showDrawingParameters || showToolParameters}>
        <MetadataAnnotationTools />
      </PanelElement>
      {
        (showDrawingParameters) &&
      <PanelElement underline>
        <DrawingParameters
          brushSize={brushSize}
          onSetBrushSize={setBrushSize}
        />
      </PanelElement>
      }
      {
        (isImageFilterOpen) &&
        <PanelElement underline>
          <ImageParameters />
        </PanelElement>
      }
      {
        (showToolParameters) &&
      <PanelElement underline>
        <ToolParameters/>
      </PanelElement>
      }
      {
        (showSelectedAnnotationArrowParametersPanel) &&
      <PanelElement underline>
        <SelectedAnnotationArrowParameters />
      </PanelElement>
      }

    </Flex>
    <Flex vertical className={styles.rightPanelBottom}>
      {
        context === ProjectDatasetContext.Training &&
      <PanelElement topSeparator>
        <TrainingSection projectId={projectId} context={context}/>
      </PanelElement>
      }
      {
        context === ProjectDatasetContext.Validation &&
      <PanelElement topSeparator>
        <div className={styles.validationPanelElement}>
          <div className={styles.validationPredictionStatusContainer}>
            <PredictingStatus projectId={projectId} context={context}/>
          </div>
          <div className={styles.downloadSection}>
            <>
              <Title level={5} className={styles.downloadTitle} onClick={openDownloadExplanationModal}>
                <FormattedMessage id="editor.right-panel-plugin-title" defaultMessage={'What is a plugin ?'} />
                <InfoOutlinedIcon className={styles.downloadTitleIcon}/>
              </Title>
              {downloadExplanationContextHolder}
            </>
            <DownloadPluginButton projectId={projectId} />
          </div>
        </div>
      </PanelElement>
      }
    </Flex>

  </div>
}
