import { type DirectMeasureWithStats, AngleUnit, DirectMeasureType } from '@app/api/openapi'
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'
import { CaretSortIcon, CaretUpIcon, CaretDownIcon } from '@radix-ui/react-icons'
import FocusIcon from '@material-design-icons/svg/outlined/center_focus_strong.svg'
import { Button } from '@shadcn-ui/components/ui/button'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { Tooltip } from 'antd'
import { EVENT_DISPATCHER } from '@app/event'

const sortableHeader = (headerTitle: React.ReactNode): ColumnDef<DirectMeasureWithStats>['header'] => {
  // eslint-disable-next-line react/display-name
  return ({ column }) => {
    return (
      <Button
        variant='ghost'
        className='px-0 w-full'
        onClick={column.getToggleSortingHandler()}
      >
        {headerTitle}
        {column.getIsSorted() === false && <CaretSortIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'desc' && <CaretDownIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'asc' && <CaretUpIcon className="ml-1 h-4 w-4" />}
      </Button>
    )
  }
}

const numberCellValue = (pixelSizeUm: number | undefined = 1, angleUnit?: AngleUnit): ColumnDef<DirectMeasureWithStats>['cell'] => {
  // eslint-disable-next-line react/display-name
  return ({ getValue }) => {
    const value = getValue() as number | undefined | null
    const valueTransform = (value: number): number => {
      if (angleUnit !== undefined) {
        if (angleUnit === AngleUnit.Radian) {
          return value * Math.PI / 180
        }
        if (angleUnit === AngleUnit.Degree) {
          return value
        }
      }
      return value
    }
    return <div className={clsx('flex grow justify-end', { 'text-clemex-offGray': !(value != null) })}>
      {
        value != null
          ? <FormattedMessage id="databrowser.cell.area.value" defaultMessage="{ value }" values={{ value: (valueTransform(value) * pixelSizeUm).toFixed(2) }}/>
          : <FormattedMessage id="databrowser.cell.not-available" defaultMessage="NA" />
      }
    </div>
  }
}

const directMeasureColumnHelper = createColumnHelper<DirectMeasureWithStats>()

interface DirectMeasureColumnsDefOptions {
  pixelSizeUm: number | undefined
  angleUnit: AngleUnit | undefined
  imageId: string | undefined
}
export const createDirectMeasureColumnsDef = ({
  pixelSizeUm = undefined,
  angleUnit = AngleUnit.Degree,
  imageId,
}: DirectMeasureColumnsDefOptions): Array<ColumnDef<DirectMeasureWithStats, unknown>> => {
  return [
    directMeasureColumnHelper.display({
      id: 'action-focus',
      header: undefined,
      cell: ({ row }) => {
        const onClickFocus = (): void => {
          if (imageId !== undefined) {
            EVENT_DISPATCHER.dispatchEditorCanvasFocusObject(imageId, [row.original.minX, row.original.minY, row.original.maxX, row.original.maxY])
          }
        }
        return <Button variant="ghost" className="p-0" onClick={onClickFocus}>
          <Tooltip title={<FormattedMessage id="databrowser.prediction-detected-object.cell.action-focus.tooltip" defaultMessage="Click to focus" />}>
            <FocusIcon />
          </Tooltip>
        </Button>
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: false,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.display({
      id: 'index',
      header: () => <FormattedMessage id="databrowser.direct-measure.column.id.header.title" defaultMessage="ID" />,
      cell: ({ row }) => {
        return row.index + 1
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.accessor('type', {
      header: sortableHeader(<FormattedMessage id="databrowser.direct-measure.column.direct-measure-type.header.title" defaultMessage="Type" />),
      cell: ({ getValue }) => {
        const directMeasureType = getValue() as DirectMeasureType
        switch (directMeasureType) {
          case DirectMeasureType.Distance:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.distance.value" defaultMessage="Distance" />
          case DirectMeasureType.Area:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.area.value" defaultMessage="Area" />
          case DirectMeasureType.Angle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.angle.value" defaultMessage="Angle" />
          case DirectMeasureType.Arc:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.arc.value" defaultMessage="Arc" />
          case DirectMeasureType.Circle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.circle.value" defaultMessage="Radius" />
          case DirectMeasureType.Ellipse:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.ellipse.value" defaultMessage="Ellipse" />
          case DirectMeasureType.Perimeter:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.perimeter.value" defaultMessage="Perimeter" />
          case DirectMeasureType.Rectangle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.rectangle.value" defaultMessage="Rectangle" />
        }
      },
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.accessor('area', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.area.header.title.um" defaultMessage="Area (μm²)" />
          : <FormattedMessage id="databrowser.direct-measure.column.area.header.title.px" defaultMessage="Area (px²)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.accessor('length', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.length.header.title.um" defaultMessage="Length (μm)" />
          : <FormattedMessage id="databrowser.direct-measure.column.length.header.title.px" defaultMessage="Length (px)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.accessor('angle', {
      header: sortableHeader(
        angleUnit === AngleUnit.Degree
          ? <FormattedMessage id="databrowser.direct-measure.column.angle.header.title.degree" defaultMessage="Angle (°)" />
          : <FormattedMessage id="databrowser.direct-measure.column.angle.header.title.rad" defaultMessage="Angle (rad)" />,
      ),
      cell: numberCellValue(undefined, angleUnit),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
    directMeasureColumnHelper.accessor('radius', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.radius.header.title.um" defaultMessage="Radius (μm)" />
          : <FormattedMessage id="databrowser.direct-measure.column.radius.header.title.px" defaultMessage="Radius (px)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats, unknown>,
  ]
}
