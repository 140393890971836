import { useMemo, type FC } from 'react'
import { DataTable } from '@app/pages/editor-page/databrowser/table/data-table'
import { createDirectMeasureColumnsDef } from '@app/pages/editor-page/databrowser/table/direct-measure-columns'
import { FormattedMessage } from 'react-intl'
import { ToggleGroup, ToggleGroupItem } from '@shadcn-ui/components/ui/toggle-group'
import { createAnalyticClassAnnotationsDownloadCSVURL, createAnalyticDirectMeasureDownloadCSVURL, createAnalyticMetadataAnnotationDownloadCSVURL, createAnalyticPredictionDetectedObjectsDownloadCSVURL, useAnalyticDataClassAnnotations, useAnalyticDataDirectMeasures, useAnalyticDataInstanceSegmentation } from '@app/api/analytic'
import { useImageProjectInfo, useProjectSettingsDirectMeasure, useProjectTrainingSnapshot } from '@app/api/hooks'
import { useEditorStore } from '@app/stores/editor'
import CloseIcon from '@material-design-icons/svg/outlined/close.svg'
import { createDetectedObjectStatsColumnsDef } from '@app/pages/editor-page/databrowser/table/detected-objects-columns'
import { DataBrowserType, useDataBrowserStore } from '@app/stores/databrowser'
import { createClassAnnotationWithStatsColumnsDef } from '@app/pages/editor-page/databrowser/table/class-annotations-columns'
import { useMetadataAnnotation } from '@app/api/metadata-annotation'
import { createMetadataAnnotationColumnsDef } from '@app/pages/editor-page/databrowser/table/metadata-annotation-columns'
import { type ClassAnnotationWithStats } from '@app/api/openapi'

export const DataBrowserTable: FC = () => {
  const { close, dataBrowserType, setDataBrowserType } = useDataBrowserStore((state) => ({
    close: state.close,
    dataBrowserType: state.dataBrowserType,
    setDataBrowserType: state.setDataBrowserType,
  }))

  const onSelectDataBrowserType = (value: DataBrowserType | ''): void => {
    if (value !== '') {
      setDataBrowserType(value)
    }
  }

  const setClassAnnotationSelectedIds = useEditorStore((state) => state.setClassAnnotationSelectedIds)
  const onCloseDataBrowser = (): void => {
    close()
    setClassAnnotationSelectedIds({})
  }

  return <div className='grow flex flex-col border-r-2 border-clemex-shellGray max-h-full max-w-full overflow-auto'>
    <div className="flex flex-row justify-between items-center gap-4 p-2 border-b-[1px] border-clemex-shellGray">
      <div className='clemex-title'>
        <FormattedMessage id='editor-page.draggable-panel.databrowser.title' defaultMessage='Data Browser' />
      </div>
      <div className="flex flex-row justify-between items-center">
        <button onClick={onCloseDataBrowser}>
          <CloseIcon />
        </button>
      </div>
    </div>
    <div className='grow flex flex-col max-h-full max-w-full overflow-auto'>
      <div className='flex gap-[16px] justify-between p-3'>
        <div className='flex items-center shrink-0 text-sm text-clemex-darkGray'>
          <FormattedMessage id="databrowser.table.type" defaultMessage="Data Type:" />
        </div>
        <ToggleGroup type="single" value={dataBrowserType} onValueChange={(value) => { onSelectDataBrowserType(value as DataBrowserType | '') }}>
          <ToggleGroupItem value={DataBrowserType.ClassAnnotation}>
            <FormattedMessage id="databrowser.table.type.selection.class-annotation" defaultMessage="Annotation" />
          </ToggleGroupItem>
          <ToggleGroupItem value={DataBrowserType.DectectedObject}>
            <FormattedMessage id="databrowser.table.type.selection.detected-object" defaultMessage="Detected Object" />
          </ToggleGroupItem>
          <ToggleGroupItem value={DataBrowserType.DirectMeasure}>
            <FormattedMessage id="databrowser.table.type.selection.direct-measure" defaultMessage="Direct Measure" />
          </ToggleGroupItem>
          <ToggleGroupItem value={DataBrowserType.MetadataAnnotation}>
            <FormattedMessage id="databrowser.table.type.selection.metadata-annotation" defaultMessage="Documentation" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      {
        dataBrowserType === DataBrowserType.DirectMeasure
          ? <DirectMeasureDataBrowserTable />
          : dataBrowserType === DataBrowserType.DectectedObject
            ? <DetectedObjectDataBrowserTable />
            : dataBrowserType === DataBrowserType.ClassAnnotation
              ? <ClassAnnotationsDataBrowserTable />
              : dataBrowserType === DataBrowserType.MetadataAnnotation
                ? <MetadataAnnotationDataBrowserTable />
                : null
      }
    </div>
  </div>
}

const DirectMeasureDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const { data: projectSettingsDirectMeasureData } = useProjectSettingsDirectMeasure(projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const { data: analyticPredictionDetectedObjects, isLoading } = useAnalyticDataDirectMeasures(selectedImageId)

  const directMeasureColumnsDef = useMemo(() => {
    return createDirectMeasureColumnsDef({
      pixelSizeUm: imageProjectInfo.data?.pixelSizeUm ?? undefined,
      angleUnit: projectSettingsDirectMeasureData?.angleUnit,
      imageId: selectedImageId,
    })
  }, [imageProjectInfo, projectSettingsDirectMeasureData, selectedImageId])

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined) {
      return undefined
    }
    return createAnalyticDirectMeasureDownloadCSVURL(selectedImageId)
  }, [selectedImageId])

  return <DataTable
    columns={directMeasureColumnsDef}
    data={isLoading ? undefined : analyticPredictionDetectedObjects?.items ?? null}
    csvURL={csvURL}
  />
}

const MetadataAnnotationDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const { data: metadataAnnotations, isLoading } = useMetadataAnnotation(projectId, selectedImageId)

  const directMeasureColumnsDef = useMemo(() => {
    return createMetadataAnnotationColumnsDef({
      imageId: selectedImageId,
    })
  }, [selectedImageId])

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined) {
      return undefined
    }
    return createAnalyticMetadataAnnotationDownloadCSVURL(selectedImageId)
  }, [selectedImageId])

  return <DataTable
    columns={directMeasureColumnsDef}
    data={isLoading ? undefined : metadataAnnotations ?? null}
    csvURL={csvURL}
  />
}

const DetectedObjectDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const { data: imageProjectInfo } = useImageProjectInfo(projectId, selectedImageId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: analyticPredictionDetectedObjects, isLoading } = useAnalyticDataInstanceSegmentation(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const detectedObjectColumnsDef = useMemo(() => {
    return createDetectedObjectStatsColumnsDef({
      pixelSizeUm: imageProjectInfo?.pixelSizeUm ?? undefined,
      imageId: selectedImageId,
      projectTrainingSnapshotId: projectTrainingSnapshot?.projectTrainingSnapshotId,
    })
  }, [imageProjectInfo, projectTrainingSnapshot, selectedImageId])

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    return createAnalyticPredictionDetectedObjectsDownloadCSVURL(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)
  }, [projectTrainingSnapshot, selectedImageId])

  const defaultHiddenColumns = useMemo(() => {
    return {
      shellArea: false,
      hullArea: false,
      minX: false,
      minY: false,
      maxX: false,
      maxY: false,
      centroidX: false,
      centroidY: false,
    }
  }, [])

  const defaultSorting = useMemo(() => {
    return [
      {
        id: 'area',
        desc: true,
      },
    ]
  }, [])

  return <DataTable
    columns={detectedObjectColumnsDef}
    data={isLoading ? undefined : analyticPredictionDetectedObjects?.items ?? null}
    csvURL={csvURL}
    defaultHiddenColumns={defaultHiddenColumns}
    defaultSorting={defaultSorting}
  />
}

const ClassAnnotationsDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const { data: imageProjectInfo } = useImageProjectInfo(projectId, selectedImageId)
  const { data: analyticPredictionDetectedObjects, isLoading } = useAnalyticDataClassAnnotations(selectedImageId)
  const [classAnnotationSelectedIds, setClassAnnotationSelectedIds] = useEditorStore((state) => [state.classAnnotationSelectedIds, state.setClassAnnotationSelectedIds])

  const classAnnotationsColumnsDef = useMemo(() => {
    return createClassAnnotationWithStatsColumnsDef({
      pixelSizeUm: imageProjectInfo?.pixelSizeUm ?? undefined,
      imageId: selectedImageId,
    })
  }, [imageProjectInfo, selectedImageId])

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined) {
      return undefined
    }
    return createAnalyticClassAnnotationsDownloadCSVURL(selectedImageId)
  }, [selectedImageId])

  const defaultHiddenColumns = useMemo(() => {
    return {
      shellArea: false,
      hullArea: false,
      minX: false,
      minY: false,
      maxX: false,
      maxY: false,
      centroidX: false,
      centroidY: false,
    }
  }, [])

  const defaultSorting = useMemo(() => {
    return [
      {
        id: 'area',
        desc: true,
      },
    ]
  }, [])

  const getRowId = useMemo(() => {
    return (row: ClassAnnotationWithStats): string => row.classAnnotationId
  }, [])

  return <DataTable
    columns={classAnnotationsColumnsDef}
    data={isLoading ? undefined : analyticPredictionDetectedObjects?.items ?? null}
    csvURL={csvURL}
    defaultHiddenColumns={defaultHiddenColumns}
    defaultSorting={defaultSorting}
    rowSelection={classAnnotationSelectedIds}
    setRowSelection={setClassAnnotationSelectedIds}
    getRowId={getRowId}
  />
}
