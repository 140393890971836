import { EVENTS_ID } from '@app/constants'

export const EVENT_DISPATCHER = {
  dispatchEditorCanvasFocusObject: (
    imageId: string,
    bbox: [number, number, number, number],
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT, {
        detail: {
          imageId,
          bbox,
        },
      }),
    )
  },
  dispatchProjectReseted: () => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_RESETED))
  },
  dispatchEditorDataBrowserClassAnnotationFocusRow: (
    classAnnotationId: string,
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_DATABROWSER_CLASS_ANNOTATION_FOCUS_ROW, {
        detail: {
          classAnnotationId,
        },
      },
    ))
  },
} as const
