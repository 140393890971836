import { ClassAnnotationObjectOverview } from '@app/pages/editor-page/object-overview/class-annotation-object-overview'
import { useDataBrowserStore } from '@app/stores/databrowser'
import { useEditorStore } from '@app/stores/editor'
import { DraggableModal } from '@components/modals/draggable-modal'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const ObjectOverviewModal: FC = () => {
  const { isOpen: dataBrowserIsOpen } = useDataBrowserStore((state) => ({
    toggleOpenClose: state.toggleOpenClose,
    isOpen: state.isOpen,
  }))

  const [
    classAnnotationSelectedId,
    setClassAnnotationSelectedIds,
  ] = useEditorStore((state) => [state.getClassAnnotationSelectedId(), state.setClassAnnotationSelectedIds])

  const isObjectOverviewOpen = classAnnotationSelectedId !== undefined && !dataBrowserIsOpen

  const onCloseObjectOverview = (): void => {
    setClassAnnotationSelectedIds({})
  }

  return <DraggableModal
    isOpen={isObjectOverviewOpen}
    onClose={onCloseObjectOverview}
    className='!w-[300px]'
    title={
      <FormattedMessage
        id="object-overview-modal.title"
        defaultMessage="Object Overview"
      />
    }
  >
    <ClassAnnotationObjectOverview />
  </DraggableModal>
}
