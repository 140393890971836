import { type OnChangeFn } from '@tanstack/react-table'
import { type StateCreator } from 'zustand'

interface CanvasSelectionState {
  classAnnotationSelectedIds: Record<string, boolean>
  thicknessMeasureObjectId?: string
}

interface CanvasSelectionComputedState {
  getClassAnnotationSelectedId: () => string | undefined
}

interface CanvasSelectionActions {
  setClassAnnotationSelectedIds: OnChangeFn<Record<string, boolean>>
  setThicknessMeasureObjectId: (id: string | undefined) => void
  resetCanvasSelection: () => void
}

const initialState: CanvasSelectionState = {
  classAnnotationSelectedIds: {},
}

export type CanvasSelectionStore = CanvasSelectionState & CanvasSelectionComputedState & CanvasSelectionActions

export const createCanvasSelectionStoreSlice: StateCreator<CanvasSelectionStore> = (set, get) => {
  return {
    ...initialState,
    getClassAnnotationSelectedId () {
      const { classAnnotationSelectedIds: selection } = get()
      return Object.keys(selection).find((id) => selection[id])
    },
    setClassAnnotationSelectedIds: (selection) => {
      set((state) => {
        if (typeof selection === 'function') {
          const newSelection = selection(state.classAnnotationSelectedIds)
          return { classAnnotationSelectedIds: newSelection, thicknessMeasureObjectId: undefined }
        } else {
          return { classAnnotationSelectedIds: selection, thicknessMeasureObjectId: undefined }
        }
      })
    },
    setThicknessMeasureObjectId: (id) => { set({ thicknessMeasureObjectId: id }) },
    resetCanvasSelection: () => { set(initialState) },
  }
}
