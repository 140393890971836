import { create } from 'zustand'

export enum DataBrowserType {
  DirectMeasure = 'DirectMeasure',
  MetadataAnnotation = 'MetadataAnnotation',
  DectectedObject = 'DectectedObject',
  ClassAnnotation = 'ClassAnnotation',
}

interface DataBrowserState {
  isOpen: boolean
  dataBrowserType: DataBrowserType
}

interface DataBrowserActions {
  open: () => void
  close: () => void
  toggleOpenClose: () => void
  setDataBrowserType: (type: DataBrowserType) => void
  reset: () => void
}

const initialState: DataBrowserState = {
  isOpen: false,
  dataBrowserType: DataBrowserType.ClassAnnotation,
}

export const useDataBrowserStore = create<DataBrowserState & DataBrowserActions>((set) => ({
  ...initialState,
  open: () => { set({ isOpen: true }) },
  close: () => { set({ isOpen: false }) },
  toggleOpenClose: () => { set((state) => ({ isOpen: !state.isOpen })) },
  setDataBrowserType: (type) => { set({ dataBrowserType: type }) },
  reset: () => { set(initialState) },
}))
