import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'
import { CaretSortIcon, CaretUpIcon, CaretDownIcon } from '@radix-ui/react-icons'
import { Button } from '@shadcn-ui/components/ui/button'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { EVENT_DISPATCHER } from '@app/event'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Feature, Map } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { swapPolygonCoordinates } from '@openlayer/helper'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import { VPE_COLORS_PALLETTE } from '@app/constants'
import { type ClassAnnotationWithStats } from '@app/api/openapi'
import { Polygon as OlPolygon } from 'ol/geom'

const sortableHeader = (headerTitle: React.ReactNode): ColumnDef<ClassAnnotationWithStats>['header'] => {
  // eslint-disable-next-line react/display-name
  return ({ column }) => {
    return (
      <Button
        variant='ghost'
        className='px-0 w-full'
        onClick={column.getToggleSortingHandler()}
      >
        {headerTitle}
        {column.getIsSorted() === false && <CaretSortIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'desc' && <CaretDownIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'asc' && <CaretUpIcon className="ml-1 h-4 w-4" />}
      </Button>
    )
  }
}

const numberCellValue = (pixelSizeUm: number | undefined = 1): ColumnDef<ClassAnnotationWithStats>['cell'] => {
  // eslint-disable-next-line react/display-name
  return ({ getValue }) => {
    const value = getValue() as number | undefined | null
    return <div className={clsx('flex grow justify-end', { 'text-clemex-offGray': !(value != null) })}>
      {
        value != null
          ? <FormattedMessage id="databrowser.cell.area.value" defaultMessage="{ value }" values={{ value: (value * pixelSizeUm).toFixed(2) }}/>
          : <FormattedMessage id="databrowser.cell.not-available" defaultMessage="NA" />
      }
    </div>
  }
}

interface ThumbnailHeaderOptions {
  imageId: string | undefined
}
const thumbnailHeader = ({ imageId }: ThumbnailHeaderOptions): ColumnDef<ClassAnnotationWithStats>['cell'] => {
  // eslint-disable-next-line react/display-name
  return ({ row }) => {
    const { geometry, colorIndex } = row.original
    const index = row.index
    const openLayerId = `ol-layer-thumbnail-class-annotation-${imageId}-${index}`
    const olRef = useRef<HTMLDivElement>(null)
    const [olCanvasMap, setOlCanvasMap] = useState<Map | null>(null)

    const olGeojsonGeometry = useMemo(() => {
      return new Feature({
        geometry: new OlPolygon(swapPolygonCoordinates(geometry.coordinates as number[][][])),
      })
    }, [geometry])

    useEffect(() => {
      const ol = new Map({
        target: openLayerId,
        controls: [],
        interactions: [],
        layers: [
          new VectorLayer({
            source: new VectorSource({
              features: [olGeojsonGeometry],
            }),
            style: new Style({
              fill: new Fill({
                color: VPE_COLORS_PALLETTE[colorIndex],
              }),
            }),
          }),
        ],
      })
      setOlCanvasMap(ol)
      return () => {
        ol.dispose()
      }
    }, [colorIndex, olGeojsonGeometry, openLayerId])

    useEffect(() => {
      const geom = olGeojsonGeometry.getGeometry()
      if (olCanvasMap !== null && geom != null) {
        olCanvasMap.getView().fit(geom.getExtent())
      }
    }, [olCanvasMap, olGeojsonGeometry])

    const onClickFocus = (): void => {
      if (imageId !== undefined) {
        EVENT_DISPATCHER.dispatchEditorCanvasFocusObject(imageId, [row.original.minX, row.original.minY, row.original.maxX, row.original.maxY])
      }
    }

    return (
      <div className="flex items-center h-max-[36px] w-max-[36px] h-min-[36px] w-min-[36px] cursor-pointer" onClick={onClickFocus}>
        <div
          id={openLayerId}
          ref={olRef}
          className='h-[36px] w-[36px] pointer-events-none'
        />
      </div>
    )
  }
}

const detectedObjectColumnHelper = createColumnHelper<ClassAnnotationWithStats>()

interface ClassAnnotationWithStatsColumnsDefOptions {
  pixelSizeUm: number | undefined
  imageId: string | undefined
}
export const createClassAnnotationWithStatsColumnsDef = ({
  pixelSizeUm = undefined,
  imageId,
}: ClassAnnotationWithStatsColumnsDefOptions): Array<ColumnDef<ClassAnnotationWithStats, unknown>> => {
  return [
    detectedObjectColumnHelper.display({
      id: 'index',
      header: () => <FormattedMessage id="databrowser.prediction-detected-object.column.index.header.title" defaultMessage="ID" />,
      cell: ({ row }) => {
        return row.index + 1
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.display({
      id: 'thumbnail',
      header: () => <FormattedMessage id="databrowser.prediction-detected-object.column.thumbnail.header.title" defaultMessage="Thumbnail" />,
      cell: thumbnailHeader({ imageId }),
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: false,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('className', {
      header: sortableHeader(<FormattedMessage id="databrowser.prediction-detected-object.column.className.header.title" defaultMessage="Class Name" />),
      cell: ({ getValue }) => getValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableGlobalFilter: true,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('area', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area.header.title.um" defaultMessage="Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area.header.title.px" defaultMessage="Area (px²)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('shellArea', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area-shell.header.title.um" defaultMessage="Shell Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area-shell.header.title.px" defaultMessage="Shell Area (px²)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('hullArea', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area-hull.header.title.um" defaultMessage="Hull Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area-hull.header.title.px" defaultMessage="Hull Area (px²)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('width', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.width.header.title.um" defaultMessage="Width (μm)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.width.header.title.px" defaultMessage="Width (px)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('height', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.height.header.title.um" defaultMessage="Height (μm)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.height.header.title.px" defaultMessage="Height (px)" />,
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('centroidX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.centroid-x.header.title.um" defaultMessage="Centroid X" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('centroidY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.centroid-y.header.title.um" defaultMessage="Centroid Y" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('minX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.min-x.header.title.um" defaultMessage="Min X" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('minY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.min-y.header.title.um" defaultMessage="Min Y" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('maxX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.max-x.header.title.um" defaultMessage="Max X" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
    detectedObjectColumnHelper.accessor('maxY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.max-Y.header.title.um" defaultMessage="Max Y" />,
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<ClassAnnotationWithStats, unknown>,
  ]
}
