import { api } from '@app/api/api'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { AlgorithmId, type TrainingProgression } from '@app/api/openapi'
import useSWR, { type SWRResponse } from 'swr'

export const ALGORITHM_ID_WITH_PROGRESSION: Array<AlgorithmId | undefined> = [AlgorithmId.ClemexNetLiteV1, AlgorithmId.ClemexNetLiteV2, AlgorithmId.ClemexGrainsegV3, AlgorithmId.ClemexGrainsegV3Aluminum, AlgorithmId.ClemexMedcleanV1]

export const useTrainingProgression = (projectId: string | undefined): SWRResponse<TrainingProgression> => {
  const swrTrainingProgression = useSWR(
    CACHE_KEYS.PROJECT_TRAINING_PROGRESSION(projectId),
    async ({ data }) => {
      return await api.getTrainingProgressionApiAlgorithmTrainProjectIdProgressionGet(data)
    },
    {
      refreshInterval: (data) => {
        const algorithmId = data?.progression?.algorithmId
        return ALGORITHM_ID_WITH_PROGRESSION.includes(algorithmId) ? 1000 : 0
      },
    },
  )
  return swrTrainingProgression
}
