import { useAnalyticDataClassAnnotations } from '@app/api/analytic'
import { useImageProjectInfo } from '@app/api/hooks'
import { useSelectedImageId } from '@app/hooks/editor'
import { useEditorStore } from '@app/stores/editor'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@shadcn-ui/components/ui/table'
import { type FC, type ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Typography } from 'antd'
import { useThicknessMeasure } from '@app/api/image-object/thickness-measure'

const { Title } = Typography

interface StatTitleProps {
  title: ReactNode
}
const StatTitle: FC<StatTitleProps> = ({ title }) => {
  return (
    <TableHead className="text-xs text-clemex-offDarkGray h-auto w-[150px]">{title}</TableHead>
  )
}

interface StatNumberValueProps {
  value: number
  pixelSizeUm?: number
  decimal?: number
}
const StatNumberValue: FC<StatNumberValueProps> = ({ value, pixelSizeUm, decimal = 2 }) => {
  return (
    <TableCell className="text-xs text-clemex-offDarkGray p-0">{(value * (pixelSizeUm ?? 1)).toFixed(decimal)}</TableCell>
  )
}

interface StatStringValueProps {
  value: string
}
const StatStringValue: FC<StatStringValueProps> = ({ value }) => {
  return (
    <TableCell className="text-xs text-clemex-offDarkGray p-0">{value}</TableCell>
  )
}

interface ObjectStatsContainerProps {
  children: ReactNode
}
const ObjectStatsContainer: FC<ObjectStatsContainerProps> = ({ children }) => {
  return (
    <TableRow className="">
      {children}
    </TableRow>
  )
}

export const ClassAnnotationObjectOverview: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const [selectedImageId] = useSelectedImageId()
  const classAnnotationSelectedId = useEditorStore((state) => state.getClassAnnotationSelectedId())
  const [thicknessMeasureObjectId, setThicknessMeasureObjectId] = useEditorStore((state) => [state.thicknessMeasureObjectId, state.setThicknessMeasureObjectId])
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const { data: classAnnotationsWithStats } = useAnalyticDataClassAnnotations(selectedImageId)

  const selectedClassAnnotation = useMemo(() => {
    if (classAnnotationSelectedId === undefined || classAnnotationsWithStats === undefined) {
      return undefined
    }
    return classAnnotationsWithStats.items.find((classAnnotation) => classAnnotation.classAnnotationId === classAnnotationSelectedId)
  }, [classAnnotationSelectedId, classAnnotationsWithStats])

  const selectedClassAnnotationIndex = useMemo(() => {
    if (classAnnotationSelectedId === undefined || classAnnotationsWithStats === undefined) {
      return undefined
    }
    for (const [index, classAnnotation] of classAnnotationsWithStats.items.entries()) {
      if (classAnnotation.classAnnotationId === classAnnotationSelectedId) {
        return index + 1
      }
    }
    return undefined
  }, [classAnnotationSelectedId, classAnnotationsWithStats])

  const pixelSizeUm = imageProjectInfo?.data?.pixelSizeUm ?? undefined

  if (selectedClassAnnotation === undefined || selectedClassAnnotationIndex === undefined) {
    return <div className='text-clemex-shellGray'>
      <FormattedMessage
        id="class-annotation-object-overview.no-selected-class-annotation"
        defaultMessage="No selected class annotation"
      />
    </div>
  }

  return <div className="flex flex-col gap-[8px]">
    <Table>
      <TableBody>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="class-annotation-object-overview.class-annotation-id" defaultMessage='ID' />
          } />
          <StatNumberValue value={selectedClassAnnotationIndex} decimal={0} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="class-annotation-object-overview.class-name" defaultMessage='Class Name' />
          } />
          <StatStringValue value={selectedClassAnnotation.className} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.area.um" defaultMessage={'Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.area.px" defaultMessage={'Area (px²)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.area} pixelSizeUm={pixelSizeUm} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.shell-area.um" defaultMessage={'Shell Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.shell-area.px" defaultMessage={'Shell Area (px²)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.shellArea} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.hull-area.um" defaultMessage={'Hull Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.hull-area.px" defaultMessage={'Hull Area (px²)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.hullArea} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.width.um" defaultMessage={'Width (μm)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.width.px" defaultMessage={'Width (px)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.width} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.height.um" defaultMessage={'Height (μm)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.height.px" defaultMessage={'Height (px)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.height} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.perimeter.um" defaultMessage={'Perimeter (μm)'} />
              : <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.perimeter.px" defaultMessage={'Perimeter (px)'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.perimeter} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.centroid.x" defaultMessage={'Centroid X'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.centroidX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.centroid.y" defaultMessage={'Centroid Y'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.centroidX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.bbox.min_x" defaultMessage={'Min X'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.minX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.bbox.min_y" defaultMessage={'Min Y'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.minY} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.bbox.max_x" defaultMessage={'Max X'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.maxX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.bbox.max_y" defaultMessage={'Max Y'} />
          } />
          <StatNumberValue value={selectedClassAnnotation.maxY} />
        </ObjectStatsContainer>
      </TableBody>
    </Table>
    <div className='flex flex-col gap-[4px]'>
      <Title level={5}>
        <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.measure.title" defaultMessage={'Measure'} />
      </Title>
      {
        thicknessMeasureObjectId === undefined
          ? <Button
              onClick={() => { setThicknessMeasureObjectId(classAnnotationSelectedId) }}
              className='text-clemex-primary'
            >
              <FormattedMessage
                id="editor-page.object-overview.class-annotation-object-overview.measure.edit"
                defaultMessage={'Thickness measure'}
              />
            </Button>
          : <ThicknessMeasureTable />
      }
    </div>
  </div>
}

const ThicknessMeasureTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const [selectedImageId] = useSelectedImageId()
  const thicknessMeasureObjectId = useEditorStore((state) => state.thicknessMeasureObjectId)

  const { data: thicknessMeasure, isLoading } = useThicknessMeasure({
    projectId,
    imageId: selectedImageId,
    measure: 'thickness',
    objectId: thicknessMeasureObjectId,
    type: 'annotation',
  })

  if (isLoading || thicknessMeasure === undefined) {
    return <div>
      <FormattedMessage
        id="editor-page.object-overview.class-annotation-object-overview.measure.loading"
        defaultMessage={'Loading...'}
      />
    </div>
  }

  return <Table>
    <TableBody>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.min" defaultMessage={'Thickness min'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMin} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.max" defaultMessage={'Thickness max'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMax} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.mean" defaultMessage={'Thickness mean'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMean} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.median" defaultMessage={'Thickness median'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMedian} />
      </ObjectStatsContainer>
    </TableBody>
  </Table>
}
